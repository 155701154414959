<template>
  <div class="navigation-menu">
    <div class="navigation-menu__container">
      <div class="navigation-menu__header">
        <UserLogo />
      </div>
      <div class="navigation-menu__links">
        <NavigationMenuLinks />
      </div>
    </div>
  </div>
</template>

<script>
import UserLogo from "../molecules/UserLogo.vue";
import NavigationMenuLinks from "../molecules/NavigationMenuLinks.vue";
export default {
  components: {
    UserLogo,
    NavigationMenuLinks,
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu {
  min-width: 110px;
  max-width: 120px;
  background: $mainColor;

  &__container {
    padding: 15px 10px;
  }

  &__header {
    @include flex-row;
  }

  &__links {
    margin-top: 15px;
  }
}
</style>
