<template>
  <div class="user-logo">
    <div class="user-logo__container">
      <div class="user-logo__img--container">
        <UserLogoImg />
      </div>
      <div class="user-logo__title">
        <p>{{ user.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import UserLogoImg from "../../assets/images/logo/user-logo.svg";
import { getStoredUserInfo } from "../../helpers/userAuth";
export default {
  data() {
    return {
      user: {},
    };
  },
  components: {
    UserLogoImg,
  },
  methods: {
    getUserName() {
      this.user = getStoredUserInfo();
    },
  },
  mounted() {
    this.getUserName();
  },
};
</script>

<style lang="scss" scoped>
.user-logo {
  &__container {
    @include flex-row;
  }

  &__img--container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 180px;
    background: #999999;
    width: 24px;
    height: 24px;
  }

  &__title {
    display: flex;
    color: $g-color-white;
    padding-left: 10px;
    font-size: $g-font-small-size;

    p {
      margin: auto 0;
    }
  }
}
</style>
