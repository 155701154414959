<template>
  <div class="main">
    <MainNavigationMenu />
    <slot />
  </div>
</template>

<script>
import { getStoredUserInfo } from "../helpers/userAuth";
import keysToCamelCase from "camelcase-keys";

import MainNavigationMenu from "../components/sections/MainNavigationMenu.vue";

export default {
  name: "Main",
  props: {
    triggerPushNotification: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MainNavigationMenu,
  },
  mounted() {
    const userInfo = getStoredUserInfo();
    if (userInfo != null) {
      window.Echo.join(`notifications.${userInfo.id}`)
        .here((data) => {
          this.$store.commit("notification/setNotificationAll", data[0].original.not_viewed.total);
          this.$store.commit(
            "notification/setNotificationDriverModerated",
            data[0].original.not_viewed.categories.driver.driver_moderated,
          );
          this.$store.commit(
            "notification/setNotificationDriverModeratedAfterEdit",
            data[0].original.not_viewed.categories.driver.driver_moderated_after_edit,
          );
          this.$store.commit(
            "notification/setNotificationOrder",
            data[0].original.not_viewed.categories.order.order_created,
          );
          console.log(data[0].original.not_viewed);
        })
        .error((data) => {
          console.log(data);
        });

      window.Echo.private(`notifications.${userInfo.id}`).notification((notification) => {
        console.log(keysToCamelCase(notification, { deep: true }));
        this.$store.dispatch("notification/getNewNotification", {
          data: keysToCamelCase(notification, { deep: true }),
          trigger: this.triggerPushNotification,
        });
        this.$store.dispatch("notification/plusCountNotification", {
          data: keysToCamelCase(notification, { deep: true }),
          trigger: this.$route.name == "Notification" ? !this.triggerPushNotification : true,
        });
      });
    }
  },
  destroyed() {
    const userInfo = getStoredUserInfo();
    if (userInfo != null) {
      window.Echo.leave(`notifications.${userInfo.id}`);
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  @include flex-row;

  min-height: 100vh;
  width: 100%;
}
</style>
