<template>
  <nav class="navigation-menu-links">
    <ul>
      <li>
        <router-link to="/" tag="a" exact> Эфир </router-link>
      </li>
      <li>
        <router-link to="/clients/1" tag="a" exact> Заказчики </router-link>
      </li>
      <li>
        <router-link to="/drivers/1" tag="a" exact>
          Водители
          <span
            v-if="notificationDriverModerated || notificationDriverModeratedAfterEdit"
            class="navigation-menu-links__read"
          />
        </router-link>
      </li>
      <li>
        <router-link to="/orders/1" tag="a" exact>
          Заказы
          <span v-if="notificationOrder" class="navigation-menu-links__badge">{{
            notificationOrder
          }}</span>
        </router-link>
      </li>
      <li>
        <router-link to="/notifications/1" tag="a" exact>
          Уведомления <span v-if="notificationAll" class="navigation-menu-links__read"
        /></router-link>
      </li>
      <li>
        <router-link class="navigation-menu-links__settings" to="/settings" tag="a" exact>
          Настройки <SettingsIcon class="navigation-menu-links__settings-icon" />
        </router-link>
      </li>
      <li>
        <a href="" @click.prevent="hendleLogout">Выйти</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from "vuex";

import SettingsIcon from "../../assets/images/icons/settings-icon.svg";

export default {
  name: "NavigationMenuLinks",
  components: {
    SettingsIcon,
  },
  methods: {
    hendleLogout() {
      this.$store.dispatch("user/logOut");
    },
  },
  computed: {
    ...mapState("notification", [
      "notificationAll",
      "notificationDriverModerated",
      "notificationDriverModeratedAfterEdit",
      "notificationOrder",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu-links {
  li {
    @include listReset;

    padding-bottom: 7px;

    a {
      position: relative;
      font-size: $g-font-small-size;
      color: $g-color-white;
    }

    a:hover {
      color: $mainLinkColor;
    }

    .router-link-active {
      color: $mainLinkColor;
    }
  }
  &__read {
    position: absolute;
    width: 6px;
    height: 6px;
    right: -10px;
    top: -2px;
    background: #eb5959;
    border-radius: 50px;
  }

  &__settings {
    &-icon {
      margin-left: 5px;
      position: relative;
      top: -2px;
    }
  }

  &__badge {
    position: absolute;
    top: -5px;
    left: 105%;
    padding: 5px 9px;
    border-radius: 50px;
    background: $mainSecondColor;
    color: $mainColor;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}
</style>
